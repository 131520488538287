var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "settings_change_photo" } },
    [
      _c("inner-page-title", { attrs: { title: "Change photo" } }),
      _c(
        "div",
        { staticClass: "container", staticStyle: { "max-width": "600px" } },
        [
          _c("user-avatar-upload", {
            attrs: {
              "upload-location":
                "User/" + _vm.$store.getters.userAuth.uid + "/Profile/photo",
              size: "150px"
            },
            on: { onPhotoUploaded: _vm.onPhotoUploaded }
          }),
          _c("br"),
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v(
              "Having a profile photo will help create trust with other members, making it easier find a match. Just click above to upload a new photo."
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }