<template>
  <div id="settings_change_photo">
		<inner-page-title title="Change photo"></inner-page-title>
		<div style="max-width: 600px" class="container">
			<user-avatar-upload @onPhotoUploaded="onPhotoUploaded" :upload-location="`User/${$store.getters.userAuth.uid}/Profile/photo`" size="150px"></user-avatar-upload>
			<br>
			<p style="text-align: center">Having a profile photo will help create trust with other members, making it easier find a match. Just click above to upload a new photo.</p>
		</div>
  </div>
</template>

<script>
import UserAvatarUpload from '@/components/UserAvatarUpload';

export default {
	components: { UserAvatarUpload },
	data() {
		return {
			loading: false,
			info: {
				commuting_times: {}
			}
		}
	},

	methods: {
		onPhotoUploaded() {
			// Set analytics
			this.$analytics.logEvent("photo_uploaded");

			// Update user claims
			setTimeout(() => {
				this.$store.dispatch('checkUserClaims', true);
			}, 8000);
		}
	},

	async mounted() {
		
	}
}
</script>

<style lang="scss" scoped>
	#settings_change_photo {
		.form-item {
			margin-bottom: 0;
		}

		.profile-image-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;

			img {
				border-radius: 50%;
				width: 100%;
				max-width: 120px;
				opacity: 0.3;
			}

			.upload-image {
				position: absolute;
			}
		}
	}
</style>